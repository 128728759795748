<template>
  <popup-modal ref="popup">
    <h2 class="title mb-4"><b>Withdraw funds</b></h2>
    <b-container class="popup" fluid>
      <b-row class="mb-3 align-items-center">
        <b-col sm="4 text-sm-left">
          <label><b>Payment method</b></label>
        </b-col>
        <b-col sm="7">
          <b-form-select v-model="paymentMethod" @change="getPaymentMethodLimits">
            <option :value="''" disabled>Please select a method</option>
            <option v-for="option in sourcePaymentMethods" :key="option.id" :value="option">
              {{ option.title }}
            </option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="mb-3 align-items-center">
        <b-col sm="4 text-sm-left">
          <label
            ><b>Amount ({{ currency }})</b></label
          >
        </b-col>
        <b-col sm="7">
          <b-input
            aria-describedby="input-amount-help"
            :state="isAmountStateValid"
            v-model="amount"
            placeholder="Enter amount"
          />
          <b-form-text class="text-sm-left mt-" id="input-amount-help"
            ><b>{{ this.limitsText }}</b></b-form-text
          >
        </b-col>
      </b-row>
      <b-row class="mb-3 align-items-center" v-for="(item, index) in paymentMethod.fields" :key="index">
        <b-col sm="4 text-sm-left">
          <label
            ><b>{{ item.title }}</b></label
          >
        </b-col>
        <b-col sm="7" v-if="item.type === 'text'">
          <b-input
            required
            v-model="item.value"
            :state="isFieldStateValid(item.id, item.value, item.validation.regexp.expression)"
          />
        </b-col>
        <b-col sm="7" v-if="item.type === 'select'">
          <b-form-select v-model="item.value" :options="item.options" value-field="value" text-field="title" />
        </b-col>
      </b-row>

      <div class="btns">
        <b-button variant="primary" :disabled="!canCreateWithdraw" @click="_withdraw">Create</b-button>
        <b-button variant="dark" @click="_cancel">{{ cancelButton }}</b-button>
      </div>
    </b-container>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";

import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
const PaymentsRepository = RepositoryFactory.get(RepositoryName.payments);
export default {
  name: "CreateWithdrawalDlg",
  components: { PopupModal },

  data: () => ({
    cancelButton: "Close", // text for cancel button
    amount: "",
    currency: "",
    paymentMethod: "",
    userId: "",

    sourcePaymentMethods: [],

    minAmount: 0,
    maxAmount: 0,
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined
  }),

  computed: {
    isAmountStateValid() {
      if (this.amount !== "") {
        return this.isValid(this.amount);
      }
      return false;
    },
    limitsText() {
      if (!this.paymentMethod) return;

      return `Min - ${this.minAmount}; Max - ${this.maxAmount};`;
    },
    canCreateWithdraw() {
      return this.isAmountStateValid;
    }
  },
  methods: {
    isValid() {
      return (
        this.amount.match(/^[0-9]{1,10}([,.][0-9]{1,5})?$/g) !== null &&
        this.amount >= this.minAmount &&
        this.amount <= this.maxAmount &&
        this.amount > 0
      );
    },
    isFieldStateValid(id, value, expression) {
      if (value != null && value !== "") {
        if (id !== "purse") return true;

        return value.toString().match(expression) !== null;
      }

      return false;
    },
    async show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.userId = opts.userId;
      this.accountId = opts.accountId;
      this.currency = opts.currency;

      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }

      await this.getPaymentMethods();

      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _withdraw() {
      this.$refs.popup.close();

      const resultItems = {
        isOk: true,
        userId: this.userId,
        paymentMethodId: this.paymentMethod.id,
        recurringId: this.paymentMethod.recurringId,
        amount: this.amount,
        deviceType: "desktop",
        fields: this.getWithdrawalFields()
      };

      this.resetState();
      this.resolvePromise(resultItems);
    },

    _cancel() {
      this.resetState();
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    getWithdrawalFields() {
      return this.paymentMethod.fields.map(x => {
        return {
          id: x.id,
          value: x.value
        };
      });
    },
    resetState() {
      this.amount = "";
      this.currency = "";
      this.paymentMethod = "";
      this.userId = "";
    },

    //#region Get
    async getPaymentMethodLimits() {
      console.log("Start receiving payment limits..");

      let resultInfo = await PaymentsRepository.getUserPaymentMethodLimits(this.accountId, this.paymentMethod.id);
      if (resultInfo.status !== 200) {
        if (resultInfo.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: resultInfo.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      this.minAmount = resultInfo.data.minValue;
      this.maxAmount = resultInfo.data.maxValue;

      console.log("Done!");
    },
    async getPaymentMethods() {
      console.log("Start receiving payment methods..");

      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.mask
        }
      };

      let resultInfo = await PaymentsRepository.getUserPaymentMethods(this.userId, this.accountId, filter);
      if (resultInfo.status !== 200) {
        if (resultInfo.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: resultInfo.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      this.sourcePaymentMethods = resultInfo.data.items;
      console.log("Done!");
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popup {
  @include media-breakpoint-tablet() {
    width: 35rem;
  }
}
</style>
